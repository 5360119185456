body {
    margin: 0;
    font-family: -apple-system, Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
}

html,
body {
    height: 100%;
}

.container {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    align-self: center;
}

.header {
    margin-bottom: 40px;
    align-self: flex-start;
}

.logo {
    height: 40px;
}

/* Main content styling */
.main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    gap: 20px;
}

.content {
    flex: 1;
    max-width: 600px;
}

.heading {
    font-size: 50px;
    font-weight: 800;
    color: #1f2937;
}

.subheading {
    font-size: 22px;
    color: #4b5563;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 180%;
    font-weight: 400;
}

.button {
    background-color: #4d7c0f;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 17px 35px;
    font-size: 20px;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-weight: 600;
}

/* Hover effect */
.button:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a shadow on hover */
    transform: translateY(-0.5px); /* Slight lift effect */
}

.button:active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15); /* Reduced shadow on click */
    transform: translateY(0.5px); /* Pressed effect */
}

.image-container {
    border-radius: 50%;
    overflow: hidden;
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    border: 5px solid rgba(77, 124, 15, 1); /* Adds a border with 50% opacity */
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.footer {
    background-color: #000; /* Dark green background */
    color: #f9fafb; /* Light text */
    padding: 10px; /* Padding around the footer */
    text-align: right; /* Align text to the left */
    width: 100vw;
    position: fixed;
    bottom: 0;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; /* Responsive padding */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer-text {
    margin: 0;
    font-size: 14px;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    display: flex;
    justify-content: flex-start; /* Align links to the left */
    gap: 15px; /* Space between links */
}

.footer-links li {
    margin: 0;
}

.footer-links a {
    text-decoration: none;
    color: #D9F99D; /* Light green color for links */
    font-size: 14px;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #A3E635; /* Brighter green on hover */
}

.social-links {
    height: 3rem;
    display: flex;
    justify-content: flex-start; /* Align social icons to the left */
    gap: 15px; /* Space between icons */
    align-items: center;
}

.social-link {
    color: #D9F99D; /* Light green for icons */
    font-size: 20px;
    transition: color 0.3s, transform 0.3s;
}

.social-link:hover {
    color: #A3E635; /* Brighter green on hover */
    transform: scale(1.1); /* Slight zoom effect */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .header {
        display: flex;
        align-self: center;
    }

    .main {
        flex-direction: column-reverse; /* Image on top for mobile devices as well */
        align-items: center;
    }

    .heading {
        font-size: 34px; /* Smaller heading for tablets */
    }

    .subheading {
        font-size: 18px; /* Smaller subheading for tablets */
    }

    .button {
        padding: 18px 30px; /* Adjusted padding for tablets */
        font-size: 18px; /* Smaller font size for button */
    }

    .image-container {
        width: 250px; /* Smaller image for tablets */
        height: 250px; /* Smaller image for tablets */
    }
}

@media (max-width: 480px) {
    .header {
        display: flex;
        align-self: center;
    }

    .main {
        flex-direction: column-reverse; /* Reverse the order to put image on top */
        align-items: center;
    }

    .header {
        text-align: center; /* Center the logo on mobile */
    }

    .heading {
        font-size: 30px; /* Even smaller heading for mobile */
    }

    .subheading {
        font-size: 18px; /* Smaller subheading for mobile */
    }

    .button {
        padding: 18px 30px; /* Reduced padding for mobile */
        font-size: 18px; /* Smaller font size for button */
    }

    .image-container {
        width: 250px; /* Smaller image for tablets */
        height: 250px; /* Smaller image for tablets */
    }
}